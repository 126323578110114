import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchClientSetting = createAsyncThunk(
    "data/clientSetting",
    async () => {
        if (window.gregario.skipFeature.includes("data/clientSetting")) {
            return
        }
        const data = await window.gregario.clientSetting()
        window.gregario.globalData.clientSetting = data?.results?.length ? data.results[0] : {}
        return data
    }
);

export const clientSettingSlice = createSlice({
    name: "clientSetting",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchClientSetting.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchClientSetting.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = {}
            if (action.payload?.results?.length) {
                state.response = action.payload.results[0]
            }

        })
        builder.addCase(fetchClientSetting.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default clientSettingSlice.reducer