import React from 'react';
import ReactDOM from "react-dom/client";
import "../node_modules/bootstrap/scss/bootstrap.scss";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './store'
import 'array-flat-polyfill'
import { QueryClient } from '@tanstack/react-query';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import * as Sentry from "@sentry/react";

if(process.env?.REACT_APP_SENTRY &&
  process.env.NODE_ENV !== "development" &&
  !window.ReactNativeWebView &&
  (window.frontend_site === "hunter" || window.frontend_site === "ondac")
){
  Sentry.init({
    dsn: process.env?.REACT_APP_SENTRY,
    integrations: [
      // Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/.*\.gregario\.app$/,
      'https://gregario.app'

    ],
    // Profiling
    profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  window.__sentry_initialized = true

}

const root = ReactDOM.createRoot(document.getElementById("root"));
const loadingLabel = ReactDOM.createRoot(document.getElementById("loading-label"));
const persister = createSyncStoragePersister({storage: window.localStorage,})

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

queryClient.setMutationDefaults(['quiz'], {
  mutationFn: (data) => {
    return window.gregario.saveQuizLegacy(data.url, data.responses, data.cacheData, data.requestCaptureClient)
  },
  gcTime: 1000 * 60 * 60  * 24, // 24 hours,
})


root.render(
  <React.StrictMode>
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
      onSuccess={() => {queryClient.resumePausedMutations()}}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </PersistQueryClientProvider>
  </React.StrictMode>,
);

loadingLabel.render(<></>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
