import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchProspectClientSetting = createAsyncThunk(
    "data/prospectClientSetting",
    async () => {
        if (window.gregario.skipFeature.includes("data/prospectClientSetting")) {
            return
        }
        const data = await window.gregario.prospectClientSetting()
        window.gregario.globalData.prospectClientSetting = data?.results?.length ? data.results[0] : {}
        return data
    }
);

export const prospectClientSettingSlice = createSlice({
    name: "prospectClientSetting",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchProspectClientSetting.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchProspectClientSetting.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = {}
            if (action.payload?.results?.length) {
                state.response = action.payload.results[0]
            }

        })
        builder.addCase(fetchProspectClientSetting.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default prospectClientSettingSlice.reducer