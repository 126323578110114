import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchEmail = createAsyncThunk(
    "data/email",
    async () => {
        if (window.gregario.skipFeature.includes("data/email")) {
            return
        }
        const data = await window.gregario.getContactData(null, {'storeopportunity_isnull':false})
        return data
    }
);

export const emailSlice = createSlice({
    name: "email",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchEmail.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchEmail.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = {}
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: '#000000'
            })) || []

        })
        builder.addCase(fetchEmail.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default emailSlice.reducer