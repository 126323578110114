import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchFilterQuizResponse = createAsyncThunk(
    "data/filterQuizResponse",
    async () => {
        if (window.gregario.skipFeature.includes("data/filterQuizResponse")) {
            return
        }
        const data = await window.gregario.getFilterQuizResponse()
        window.gregario.globalData.filterQuizResponse = data?.results
        return data
    }
);

export const filterQuizResponseSlice = createSlice({
    name: "filterQuizResponse",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchFilterQuizResponse.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchFilterQuizResponse.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            state.selectedOptions = []
        })
        builder.addCase(fetchFilterQuizResponse.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default filterQuizResponseSlice.reducer