import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchMetrics = createAsyncThunk(
    "data/metrics",
    async () => {
        if (window.gregario.skipFeature.includes("data/metrics")) {
            return
        }
        const data = await window.gregario.getMetrics()
        window.gregario.globalData.Metrics = data?.results
        return data
    }
);

export const metrics = createSlice({
    name: "metrics",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMetrics.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchMetrics.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
        })
        builder.addCase(fetchMetrics.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default metrics.reducer