import { configureStore } from '@reduxjs/toolkit'
import statReducer from './features/stats/statSlice'
import contactTypesReducer from './features/contacts/contactTypesSlice'
import constructionTypesReducer from './features/constructions/constructionTypeSlice'
import constructionStateReducer from './features/constructions/constructionStateSlice'
import realEstateCompanyReducer from './features/constructions/realEstateCompanySlice'
import builderCompanyReducer  from './features/constructions/builderCompanySlice'
import categoryReviewReducer from './features/prospectClients/categoryReviewSlice'
import deliveryReducer from './features/prospectClients/deliverySlice'

import opportunityCategoryListReducer from './features/prospectClients/opportunityCategoryListSlice'
import opportunityTagListReducer from './features/prospectClients/opportunityTagListSlice'
import availabilityTypesReducer from './features/prospectClients/availabilityTypesSlice'
import featuresReducer from './features/prospectClients/featuresSlice'
import paymentMethodsReducer from './features/prospectClients/paymentMethodsSlice'
import photoTypesReducer from './features/prospectClients/photoTypesSlice'
import relevanceCalculationsReducer from './features/prospectClients/relevanceCalculationsSlice'

import priceLevelReducer from './features/prospectClients/priceLevelSlice'
import restaurantTypeReducer from './features/prospectClients/restaurantTypeSlice'
import restaurantTypeCustomerReducer from './features/prospectClients/restauranTypeCustom'
import creatorProspectClientReducer from './features/prospectClients/creatorProspectClient'
import quizContentTypesReducer from './features/quiz/quizContentTypesSlice'
import captureStatusReducer from './features/prospectClients/captureStatusSlice'
import countryReducer from './features/postalCodes/countrySlice'
import countryComponentReducer from './features/postalCodes/countryComponentSlice'
import contentTypeReducer from './features/contentType/contentTypeSlice'
import stateReducer from './features/postalCodes/stateSlice'
import todoStatusReducer from './features/todo/todoStatusSlice'
import administrativeAreaReducer from './features/postalCodes/administrativeAreaSlice'
import subAdministrativeAreaReducer from './features/postalCodes/subAdministrativeAreaSlice'
import localityReducer from './features/postalCodes/localitySlice'
import zoneReducer from './features/postalCodes/zoneSlice'
import userReducer from './features/users/usersSlice'
import usersPortafolioReduce from './features/users/usersPortafolioSlice'
import userResoponseReducer from  './features/users/usersResponseSlice'
import saleOpportunityGroupReducer from './features/prospectClients/saleOpportunityGroupSlice'
import saleOpportunityReducer from './features/prospectClients/saleOpportunitySlice'
import routeClientStatusSlice from './features/route/RouteStatusSlice'
import storeOpportunitySettingReducer from './features/prospectClients/storeOpportunitySettingSlice'
import prospectClientSettingReducer from   './features/prospectClients/prospectClientSettingSlice'
import clientSettingReducer from   './features/clients/clientSettingSlice'
import ratingReducer from './features/prospectClients/ratingSlice'
import clientLoyaltyStatusReducer from './features/clientLoyalties/loyaltyStatusSlice'
import storeChainScalesReducer from './features/prospectClients/storeChainScaleSlice'
import storeChainReducer from './features/prospectClients/storeChainSlice'
import filterQuizResponseReducer from './features/quiz/filterQuizResponse'
import gmapReducer from './features/gmap/gmapSlice'
import prospectClientSourceReducer from './features/prospectClients/prospectClientSourceSlice'
import requestDisabledReducer from './features/prospectClients/requestDisabledSlice'
import emailReducer from './features/prospectClients/emailSlice'

import discountsReducer from './features/sales/discountsSlice'
import documentTypesReducer from './features/sales/documentTypesSlice'
import taxesReducer from './features/sales/taxesSlice'
import saleSummaryMetricsReducer from './features/sales/saleSummaryMetricsSlice'

import salesMixSettingReducer from './features/sales/salesMixSettingSlice'
import salesMixGroupReducer from './features/sales/salesMixGroupSlice'
import salesMixReducer from './features/sales/salesMixSlice'
import salesMixItemReducer from './features/sales/salesMixItemSlice'

import metricsReducer from './features/metrics/metricsSlice'
import periodAggregationsReducer from './features/metrics/periodAggregationsSlice'
import metricsEvaluationsReducer from './features/metrics/metricsEvaluationsSlice'
import dataViewsReducer from './features/metrics/dataViewsSlice'

import periodFormatsReducer from './features/gregarioFrontend/periodFormatsSlice'
import widgetsReducer from './features/gregarioFrontend/widgetsSlice'

import materialsReducer from './features/materials/materialsSlice'
import materialsCategoriesReducer from './features/materials/materialsCategoriesSlice'

export default configureStore({
    reducer: {
        stats: statReducer,
        contactTypes: contactTypesReducer,
        categoryReviews: categoryReviewReducer,
        delivery: deliveryReducer,
        opportunityCategoryList: opportunityCategoryListReducer,
        opportunityTagList: opportunityTagListReducer,
        availabilityTypes: availabilityTypesReducer,
        features: featuresReducer,
        paymentMethods: paymentMethodsReducer,
        photoTypes: photoTypesReducer,
        relevanceCalculations: relevanceCalculationsReducer,
        priceLevels: priceLevelReducer,
        restaurantTypes: restaurantTypeReducer,
        restaurantTypeCustom: restaurantTypeCustomerReducer,
        creatorProspectClient:creatorProspectClientReducer,
        quizContentTypes: quizContentTypesReducer,
        constructionTypes: constructionTypesReducer,
        constructionState :constructionStateReducer,
        realEstateCompany: realEstateCompanyReducer,
        builderCompany: builderCompanyReducer,
        captureStatus: captureStatusReducer,
        contentType: contentTypeReducer,
        country: countryReducer,
        countryComponent: countryComponentReducer,
        state: stateReducer,
        administrativeArea: administrativeAreaReducer,
        subAdministrativeArea: subAdministrativeAreaReducer,
        locality: localityReducer,
        zone: zoneReducer,
        users: userReducer,
        usersPortafolio:usersPortafolioReduce,
        usersResponse: userResoponseReducer,
        saleOpportunityGroup:saleOpportunityGroupReducer,
        saleOpportunity:saleOpportunityReducer,
        routeClientStatus:routeClientStatusSlice,
        storeOpportunitySetting: storeOpportunitySettingReducer,
        prospectClientSetting: prospectClientSettingReducer,
        clientSetting: clientSettingReducer,
        ratings: ratingReducer,
        clientLoyaltyStatus: clientLoyaltyStatusReducer,
        storeChainScales: storeChainScalesReducer,
        storeChain: storeChainReducer,
        filterQuizResponse: filterQuizResponseReducer,
        todoStatus:todoStatusReducer,
        gmap: gmapReducer,
        prospectClientSource: prospectClientSourceReducer,
        discounts: discountsReducer,
        documentTypes: documentTypesReducer,
        taxes: taxesReducer,
        salesMixSetting: salesMixSettingReducer,
        salesMixGroup:salesMixGroupReducer,
        salesMix: salesMixReducer,
        salesMixItem: salesMixItemReducer,
        widgets: widgetsReducer,
        periodFormats: periodFormatsReducer,
        metrics: metricsReducer,
        dataViews: dataViewsReducer,
        periodAggregations: periodAggregationsReducer,
        metricsEvaluations: metricsEvaluationsReducer,
        materials: materialsReducer,
        materialsCategories: materialsCategoriesReducer,
        saleSummaryMetrics: saleSummaryMetricsReducer,
        requestDisabled: requestDisabledReducer,
        email: emailReducer,
    },
})
