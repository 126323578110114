import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchContactTypes = createAsyncThunk(
    "data/contactTypes",
    async () => {
        const data = await window.gregario.contactTypes()
        return data
    }
);

export const contacTypesSlice = createSlice({
    name: "contactTypes",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchContactTypes.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchContactTypes.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
        })
        builder.addCase(fetchContactTypes.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default contacTypesSlice.reducer