import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchRequestDisabled = createAsyncThunk(
    "data/requestDisabled",
    async () => {
        if (window.gregario.skipFeature.includes("data/requestDisabled")) {
            return
        }
        const userId = window.gregario.userInfo?.id
        if (userId){
            const data = await window.gregario.objectiveDisabledRequest(null, 'status-by-user', {'requested_by': userId}, null)
            return data
        }
        return
    }
);

export const requestDisabled = createSlice({
    name: "requestDisabled",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRequestDisabled.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchRequestDisabled.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.status, label: item.name, color: item.color ? item.color : '#000000'
            })) || []
        })
        builder.addCase(fetchRequestDisabled.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default requestDisabled.reducer