import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchCreatorProspectClient = createAsyncThunk(
    "data/creatorProspectClient",
    async () => {
        if (window.gregario.skipFeature.includes("data/creatorProspectClient")) {
            return
        }
        // const data = await window.gregario.getCreatorProspectClient(null, {'created_by__isnull':false})
        // window.gregario.globalData.creatorProspectClient = data?.results ? data?.results : data
        // return data
    }
);

export const creatorProspectClientSlice = createSlice({
    name: "creatorProspectClient",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCreatorProspectClient.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchCreatorProspectClient.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.map((item) => ({
                value: item.id, label: item.username, color: item.color ? item.color : '#000000', machine_name: item.username
            })) || []
        })
        builder.addCase(fetchCreatorProspectClient.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default creatorProspectClientSlice.reducer