import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchStoreChain = createAsyncThunk(
    "data/storeChain",
    async () => {
        if (window.gregario.skipFeature.includes("data/storeChain")) {
            return
        }
        const data = await window.gregario.storeChains(null)
        window.gregario.globalData.storeChain = data?.results
        return data
    }
);

export const storeChainScalesSlice = createSlice({
    name: "storeChain",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchStoreChain.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchStoreChain.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000'
            })) || []
        })
        builder.addCase(fetchStoreChain.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default storeChainScalesSlice.reducer