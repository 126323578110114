import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchPeriodAggregations = createAsyncThunk(
    "data/periodAggregations",
    async () => {
        if (window.gregario.skipFeature.includes("data/periodAggregations")) {
            return
        }
        const data = await window.gregario.getPeriodAggregations()
        window.gregario.globalData.PeriodAggregations = data?.results
        return data
    }
);

export const periodAggregations = createSlice({
    name: "periodAggregations",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPeriodAggregations.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchPeriodAggregations.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
        })
        builder.addCase(fetchPeriodAggregations.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default periodAggregations.reducer