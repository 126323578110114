import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchMaterials = createAsyncThunk(
    "data/materials",
    async (ids, thunkAPI) => {
        if (window.gregario.skipFeature.includes("data/materials")) {
            return
        }
        let result = []
        let response = null
        let page=1
        let query = {page: page}
        if (ids) {
            query.id__in = ids
            let state = thunkAPI.getState();
            result = state.data?.result?.filter(item => !ids.includes(item.id)) || []
        }
        do {
            query.page = page
            response = await window.gregario.getMaterials(null, query)
            result = [...result, ...(response?.results || [])]
            page = response.current_page + 1
        } while (response && response.current_page < response.pages)
        window.gregario.globalData.Materials = result
        response.results = result
        return response
    }
);

export const materials = createSlice({
    name: "materials",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchMaterials.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchMaterials.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (!state.response?.results) {
                state.selectedOptions = []
            }
            state.selectedOptions = state.response?.results?.map((item) => ({
                value: item.id, label: item.name, color: item.color ? item.color : '#000000'
            }))
        })
        builder.addCase(fetchMaterials.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default materials.reducer