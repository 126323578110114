import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'StatusRoute', error: null, response: {}}

export const fetchRouteStatus = createAsyncThunk(
    "data/visitStatus",
    async () => {
        if (window.gregario.skipFeature.includes("data/visitStatus")) {
            return
        }

        const data = await window.gregario.routeStatus()
        let process_data = data.results.map(item => {
            item.color = window.gregario.mapSettings.colors[item.color]
            return item
        })
        return process_data
    }
);

export const routeClientStatusSlice = createSlice({
    name: "visitStatus",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchRouteStatus.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchRouteStatus.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
        })
        builder.addCase(fetchRouteStatus.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default routeClientStatusSlice.reducer
