import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

const initialState = {state: 'idle', error: null, response: {}}

export const fetchQuizContentTypes = createAsyncThunk(
    "data/quizContentTypes",
    async () => {
        if (window.gregario.skipFeature.includes("data/quizContentTypes")) {
            return
        }
        const data = await window.gregario.quizContentTypes()
        window.gregario.globalData.quizContentTypes = data?.results
        return data
    }
);

export const quizContentTypesSlice = createSlice({
    name: "quizContentTypes",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchQuizContentTypes.pending, (state) => {
            state.status = 'loading';
        })
        builder.addCase(fetchQuizContentTypes.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.response = action.payload
            if (state.response?.results) {
                state.visitObjectTypeMap = window.gregario.generateVisitObjectTypeMap(state.response.results)
                window.gregario.globalData.visitObjectTypeMap = state.visitObjectTypeMap
            }

        })
        builder.addCase(fetchQuizContentTypes.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        })
    },
});

export default quizContentTypesSlice.reducer