import {createSlice } from '@reduxjs/toolkit'

const initialState = {libraryLoaded: false}

export const gmapSlice = createSlice({
    name: "gmap",
    initialState: initialState,
    reducers: {
        setLibraryLoaded: (state, action) => {
            state.libraryLoaded = action.payload
        }
    },
});

export const { setLibraryLoaded } = gmapSlice.actions
export default gmapSlice.reducer